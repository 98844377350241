.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* frontend/src/components/pages/Dashboard.css */

.dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

.dashboard h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.metrics {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.metric-box {
  flex: 1;
  min-width: 150px;
  padding: 20px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-align: center;
}

.metric-box h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.metric-box p {
  font-size: 14px;
  color: #757575;
}

.portfolio-summary {
  margin-top: 30px;
}

.portfolio-summary h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.summary-item {
  margin-bottom: 20px;
}

.summary-item p {
  margin: 0;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  height: 10px;
  margin-top: 10px;
}

.progress-bar .filled {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.live-offerings {
  margin-top: 30px;
}

.live-offerings h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.live-offering {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.live-offering img {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.live-offering h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.live-offering p {
  font-size: 14px;
  color: #757575;
}

.live-offering button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.live-offering button:hover {
  background-color: #0056b3;
}

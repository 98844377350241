@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Background Colors */
    --bg-primary-light: #fafbfc;
    --bg-primary-dark: #14161a;
    --bg-secondary-light: #ffffff;
    --bg-secondary-dark: #24272b;
    --bg-transparent: transparent;

    /* Button colors */
    --bg-button-default: #10a2f5;
    --bg-button-secondary: #007bff;
    --bg-button-accent: #9c27b0;
    --bg-button-accent-hover: #7b1fa2;

    /* Accent and Borders */
    --accent-color: #10a2f5;
    --border-light: #e5e7eb;
    --border-dark: #43454a;

    /* Text Colors */
    --foreground-light: #10101a;
    --foreground-dark: #fafafa;
    --primary-foreground: #fafafa;
    --secondary-foreground: #1a1b2f;
    --muted-foreground: #737a8d;
    --destructive-foreground: #fafafa;
    --popover-foreground: #10101a;

    /* Card and Popover */
    --card: #ffffff;
    --card-foreground: #10101a;
    --popover: #ffffff;

    /* General Colors */
    --background: #ffffff;
    --primary: #1a1b2f;
    --secondary: #f0f1f7;
    --muted: #f0f1f7;
    --destructive: #e53535;
    --ring: #10101a;

    /* Chart Colors */
    --chart-1: #5ba3f9;
    --chart-2: #f9ba5b;
    --chart-3: #dc9ee9;
    --chart-4: #a6d162;
    --chart-5: #f86969;
  }

  .dark {
    --bg-primary: var(--bg-primary-dark);
    --bg-secondary: var(--bg-secondary-dark);
    --border-color: var(--border-dark);

    /* Dark Mode Colors */
    --background: #10101a;
    --foreground: #fafafa;
    --primary-foreground: #fafafa;
    --card: rgba(59, 59, 71, 0.8);
    --card-foreground: #fafafa;
    --popover: #10101a;
    --popover-foreground: #fafafa;
    --primary: #fafafa;
    --secondary: #31363f;
    --muted: #31363f;
    --muted-foreground: #737a8d;
    --accent: #31363f;
    --destructive: #e53535;
    --border: #31363f;
    --input: #31363f;
    --ring: #737a8d;

    /* Dark Mode Chart Colors */
    --chart-1: #5ba3f9;
    --chart-2: #f9ba5b;
    --chart-3: #dc9ee9;
    --chart-4: #a6d162;
    --chart-5: #f86969;
    --bg-transparent: transparent;

    /* MUI DataGrid specific styles */
    --data-grid-aggregation-row-bg: var(--bg-secondary-dark);
    --data-grid-aggregation-row-color: var(--foreground-dark);
  }

  .light {
    --bg-primary: var(--bg-primary-light);
    --bg-secondary: var(--bg-secondary-light);
    --border-color: var(--border-light);

    /* MUI DataGrid specific styles */
    --data-grid-aggregation-row-bg: var(--bg-secondary-light);
    --data-grid-aggregation-row-color: var(--foreground-light);
  }

  /* Specific DataGrid styles */
  .MuiDataGrid-row.MuiDataGrid-row--aggregation {
    background-color: var(--data-grid-aggregation-row-bg) !important;
    color: var(--data-grid-aggregation-row-color) !important;
  }
  .MuiDataGrid-row {
    background-color: var(--bg-secondary) !important;
    color: var(--foreground) !important;
  }

  .bg-white {
    @apply bg-[var(--bg-secondary-light)];
  }
  .dark .bg-white {
    @apply bg-[var(--bg-secondary-dark)];
  }

  .main-content {
    margin-left: 16rem; /* Custom sidebar margin */
  }
  .tab-hover-white:hover {
    @apply text-white;
  }
  body {
    @apply bg-[var(--bg-primary-light)] text-gray-800;
  }
  .dark body {
    @apply bg-[var(--bg-primary-dark)] text-gray-200;
  }

  .bg-white {
    @apply bg-[var(--bg-secondary-light)];
  }
  .dark .bg-white {
    @apply bg-[var(--bg-secondary-dark)];
  }

  h1,
  h2,
  h3 {
    color: #333;
  }
  .dark h1,
  .dark h2,
  .dark h3 {
    color: #fff;
  }

  button {
    transition:
      background-color 0.3s,
      color 0.3s;
  }

  /* Remove these button hover styles */
  /*
  button:hover {
    background-color: #007bff;
    color: #fff;
  }
  .dark button {
    background-color: #333;
    color: #fff;
  }
  .dark button:hover {
    background-color: #007bff;
  }
  */

  .dark .quick-filter-button:hover {
    background-color: var(--bg-button-accent-hover);
  }

  .dark .quick-filter-button.active {
    background-color: var(--bg-button-accent);
  }

  .dark .text-black {
    color: #fff;
  }

  .dark .border {
    border-color: #fff; /* Updated for dark mode border color */
  }

  .p-2 {
    padding: 0.5rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .grid {
    display: grid;
  }

  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .gap-4 {
    gap: 1rem;
  }

  .label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #333;
  }
  .dark .label {
    color: #fff; /* Updated label color for dark mode */
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
